import React from 'react'
import { Link } from 'gatsby'
import Obfuscate from 'react-obfuscate'

import SEO from 'components/seo'
import { default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { Nav } from 'components/nav'
import { MeetUsMenu } from 'components/pages/MeetUsPage'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const SofiaPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO title="Meet Us - Sofia" pageUrl="/meet-us/" description="Have a challenge in mind? Let's talk." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 py-6">
        <HeaderLink className="text-primary-2" />
        <Link to="/meet-us/" className="block pt-12 lg:py-12 text-left">
          <h1 className="pt-4 pb-4 text-4xl lg:text-5xl text-shadow">Meet us</h1>
        </Link>
      </Header>
      <Section className="z-20 vcard h-card" gridRow={2}>
        <div className="grid grid-cols-22ch-auto gap-y-6 h-adr adr">
          <h2 className="col-span-2 row-start-2 lg:row-start-1 text-6xl sm:text-7xl lg:text-8xl text-right text-shadow p-locality locality">Sofia</h2>
          <MeetUsMenu className="col-span-2 lg:col-span-1 row-start-1 lg:row-start-2" />
          <div className="col-span-2 lg:col-span-1 row-start-3 lg:row-start-2 text-right text-primary-2">
            <p className="text-3xl lg:text-4xl p-country-name country-name">Bulgaria</p>
            <div className="text-xl lg:text-2xl font-normal tracking-normal">
              <Obfuscate email="contact+www@tumba.solutions" headers={{ subject: 'Ask Tumba' }} aria-label="Email us">
                Contact us
              </Obfuscate>
            </div>
          </div>
        </div>
      </Section>
      <BackgroundWithGrid image="meet-us-sofia" gridRow="1/4" />
      <div className="z-0 bg-primary-1" style={{ gridColumn: '1 / -1', gridRow: '1 / 4', opacity: 0.9 }} />
      <div className="z-0 h-16" style={{ gridRow: 3, gridColumn: '1/-1', background: 'linear-gradient(#00edae00, #00edae)' }} />
      <Nav className="z-20 text-primary-2" gridRow={4} />
    </MainWithMenu>
  </Layout>
)

export default SofiaPage
